<ion-content [attr.dir]="translateService.direction()">

  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Driving License?" | translate }}</h3>

    <ion-buttons *ngIf="candidate" class="btn-wrapper">

      <ion-button class="btn-yes" 
        [disabled]="isLoading"
        [class.active]="candidate.candidate_driving_license == 1" (click)="submit(1)">          
        <ion-spinner *ngIf="isLoading == 1"></ion-spinner>
        <span *ngIf="isLoading != 1">{{ "Yes" | translate }}</span>
      </ion-button>

      <ion-button class="btn-no" 
        [disabled]="isLoading"
        [class.active]="candidate.candidate_driving_license == 2" (click)="submit(2)">          
        <ion-spinner *ngIf="isLoading == 2"></ion-spinner>
        <span *ngIf="isLoading != 2">{{ "No" | translate }}</span>
      </ion-button>
    </ion-buttons>

  </div>
</ion-content>
