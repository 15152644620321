<ion-content [attr.dir]="translateService.direction()">

  <div class="max-width" *ngIf="company">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <ion-item lines="none" *ngIf="company.company_logo">
      <div tabindex="0"></div>
      <ion-thumbnail slot="start" >
        <ion-img [src]="awsService.cloudinaryUrl + company.company_logo" (ionError)="setNull()"></ion-img>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <div tabindex="0"></div>
      <ion-label class="ion-text-wrap">
        <h3 *ngIf="translateService.currentLang == 'en'">
          {{ company.company_common_name_en? company.company_common_name_en: company.company_name }}
        </h3>
        <h3 *ngIf="translateService.currentLang != 'en'">
          {{ company.company_common_name_ar? company.company_common_name_ar: company.company_name }}
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="none" *ngIf="company.company_website">
      <div tabindex="0"></div>
      <ion-label>
        <a target="_blank" class="website" href="{{ company.company_website }}">
          {{ company.company_website }}
        </a>
      </ion-label>
    </ion-item>

    <ion-item lines="none" *ngIf="company.company_description_en">
      <div tabindex="0"></div>
      <ion-label class="ion-text-wrap">
        <p class="desc" [innerHTML]="translateService.langContent(company.company_description_en, company.company_description_ar)">
        </p>
      </ion-label>
    </ion-item>
   
    <ion-slides *ngIf="company.brands" [options]="slideOpts" pager="false">
      <ion-slide *ngFor="let brand of company.brands">
        
        <div class="no-logo" *ngIf="!brand.brand_logo">
          <span>{{ translateService.langContent(brand.brand_name_en, brand.brand_name_ar) }} </span>
        </div>

        <img *ngIf="brand.brand_logo" src="{{ awsService.cloudinaryUrl + 'company-brand/' + brand.brand_logo }}" />
      </ion-slide>
    </ion-slides>
  </div>

</ion-content>
