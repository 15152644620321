<ion-content [attr.dir]="translateService.direction()">

  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Tell us more about yourself. What makes you special?" | translate }}</h3>

    <form [formGroup]="form" *ngIf="form">

      <ion-list>
        <ion-textarea #inputToFocus maxlength=100 formControlName="objective" rows="5"
          placeholder="{{ 'e.g. Open for an opportunity in social media warehouse in zara mall' | translate }}"></ion-textarea>
          
        <p [class.error]="inputToFocus.value.length == 100" class="txt-char-count">
          {{ "txt_char_left" | translate : { count : 100-inputToFocus.value.length } }}
        </p>
      </ion-list>

      <ion-button (click)="submit()" class="btn-submit" [disabled]="!form.valid || isLoading" type="submit">
        <ion-spinner *ngIf="isLoading"></ion-spinner>
        <span *ngIf="!isLoading">{{ "Submit" | translate }}</span>
      </ion-button>
    </form>
  </div>
</ion-content>
