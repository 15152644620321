<ion-content [attr.dir]="translateService.direction()">

    <div class="max-width">
        <ion-button fill="clear" (click)="dismiss()" class="btn-close">
            <img src="assets/images/ic_cancel.svg" />
        </ion-button>

        <h3>{{ "Upload your CV / Resume / Portfolio" | translate }}</h3>

        <div *ngIf="!uploadingCv">

            <div class="selected-file" *ngIf="candidate.candidate_resume || candidate.tempLocation">

                <div class="extension">{{ uploadedFileExtension }}</div>

                <div class="fileName">{{ candidate.candidate_resume }}</div>

                <a target="_blank" (click)="$event.stopPropagation()" *ngIf="!candidate.tempLocation"
                    href="{{ getResumeUrl(candidate) }}">
                    <ion-icon src="assets/images/md-arrow-down.svg"></ion-icon>
                </a>

                <a target="_blank" (click)="$event.stopPropagation()" *ngIf="candidate.tempLocation"
                    href="{{ candidate.tempLocation }}">
                    <ion-icon src="assets/images/md-arrow-down.svg"></ion-icon>
                </a>
            </div>

            <ion-button tabindex="2" class="fileUpload" full (click)="mobileUpload();" *ngIf="platform.is('hybrid')">
                <ion-icon src="assets/images/md-cloud-upload.svg"></ion-icon>
                <span *ngIf="candidate.candidate_resume || candidate.tempLocation">{{ "Replace file" | translate }}</span>
                <span *ngIf="!candidate.candidate_resume && !candidate.tempLocation">{{ "Select file" | translate }}</span>
            </ion-button>

            <ion-button tabindex="3" class="fileUpload" full *ngIf="!platform.is('hybrid')">
                <ion-icon src="assets/images/md-cloud-upload.svg"></ion-icon>
                <span *ngIf="candidate.candidate_resume || candidate.tempLocation">{{ "Replace file" | translate }}</span>
                <span *ngIf="!candidate.candidate_resume && !candidate.tempLocation">{{ "Select file" | translate }}</span>
                <input class="upload" type='file' #fileInput (change)="browserUpload($event)">
                <!-- .png,.jpg,.jpeg,.pdf,.doc,.docx -->
            </ion-button>

            <p class="txt-format" *ngIf="!candidate.candidate_resume && !candidate.tempLocation">
                {{ "Supported Formats:" | translate }} <br />
                <span>JPG, PNG, PDF, Word</span>
            </p>

        </div>

        <div class="progress-wrapper" *ngIf="uploadingCv">
            <div class="progress-box">

                <h4>{{ "Uploading…" | translate }} <span>{{ progress + '%' }}</span></h4>

                <div class="progress-bar">
                    <div class="progress" [style.width]="progress + '%'"></div>
                </div>

                <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
                </ion-icon>
            </div>
        </div>

        <ion-button class="btn-submit" *ngIf="candidate.tempLocation" (click)="submit()" [disabled]="loading || uploadingCv">
            <span *ngIf="!loading">{{ "Save" | translate }}</span> 
            <ion-spinner *ngIf="loading"></ion-spinner>
        </ion-button>

    </div>
</ion-content>