<ion-content [attr.dir]="translateService.direction()">
  <div class="max-width">

    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Update email" | translate }}</h3>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

      <ion-input type="text" inputmode="email" #emailInput enterkeyhint='{{ "Continue" | translate }}'
               formControlName="email" placeholder="{{ 'Your Email' | translate }}"></ion-input>

      <ion-button class="btn-signup" [disabled]="!form.valid || isLoading" type="submit">
        <ion-spinner *ngIf="isLoading"></ion-spinner>
        <span *ngIf="!isLoading">{{ "Save" | translate }}</span>
      </ion-button>
    </form>
  </div>

</ion-content>
