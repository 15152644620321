<div [attr.dir]="translateLabel.direction()" *ngIf="false && !platform.is('mobile')" [class]="position">

  <select  class="native-select" (change)="onDayChange($event)"
    *ngIf="pickerFormat && pickerFormat.indexOf('D') !== -1">
    <option value="" disabled selected>{{'DD' | translate}}</option>
    <option [value]="row.day" [selected]="selectedDate == row.day"  *ngFor="let row of days"
      [disabled]="!row.isSelectable">
      {{ row.day }}
    </option>
  </select>

  <select class="native-select" (change)="onMonthChange($event)"
    *ngIf="pickerFormat && pickerFormat.indexOf('M') !== -1">
    <option value="" disabled selected>{{'MM' | translate}}</option>
    <option [value]="row.month" [selected]="selectedMonth == row.month" *ngFor="let row of months" [disabled]="!row.isSelectable">
      {{ row.month }}
    </option>
  </select>

  <select class="native-select" (change)="onYearChange($event)">
    <option value="" disabled selected>{{'YYYY' | translate}}</option>
    <option [value]="row.year" [selected]="selectedYear == row.year" *ngFor="let row of years">
      {{ row.year }}
    </option>
  </select>
</div>

<div [attr.dir]="translateLabel.direction()" *ngIf="true || platform.is('mobile')">
  <ion-datetime [presentation]="presentation" [min]="minDateString" [max]="maxDateString"
    doneText="{{ 'Done' | translate }}" cancelText="{{ 'Cancel' | translate }}" [value]="value"
    (ionChange)="onDateChange($event)"></ion-datetime>
</div> 