<ion-content [attr.dir]="translateService.direction()" class="ion-padding">

  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Where do you study?" | translate }}</h3>

    <ion-searchbar mode="md" searchIcon="../assets/images/ic_searchbar.svg" class="no-shadow search-bar"
      (ionChange)="onSearchInput($event)" [value]="query"
      placeholder="{{ 'Type to search' | translate }}">
    </ion-searchbar>

    <ion-list *ngIf="candidate">
      
      <ion-item *ngFor="let university of universities" tappable (click)="rowSelected(university)">
        <div tabindex="0"></div>
        {{ translateService.langContent(university.university_name_en, university.university_name_ar) }}
        <ion-icon slot="end" name="checkmark" *ngIf="!saving && candidate.university_id == university.university_id">
        </ion-icon>
        <ion-spinner slot="end" *ngIf="saving && candidate.university_id == university.university_id"></ion-spinner>

      </ion-item>
      
      <ion-item *ngIf="universities.length == 0 && query.length > 0 createUniversity" tappable (click)="createUniversity()">
        <div tabindex="0"></div>
        {{ 'txt_add_univerity' | translate : { query : query } }}
        <ion-spinner slot="end" *ngIf="adding"></ion-spinner>
      </ion-item>

      <loading-modal [loading]="loading" type="text-list"></loading-modal>

      <ion-infinite-scroll *ngIf="currentPage < totalPage" (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
      
      <h2 class="no-result-found" *ngIf="universities && universities.length == 0 && !loading && query.length == 0">
        {{ 'no_result_found' | translate : {'query' : query } }}
      </h2>
      
    </ion-list>
  </div>
</ion-content>
