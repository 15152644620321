<ion-content [attr.dir]="translateService.direction()">

  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Civil ID number" | translate }}</h3>

    <form [formGroup]="form" *ngIf="form">

      <ion-list>
        <ion-input formControlName="civil_id" inputmode="numeric" #inptId
          placeholder="{{ 'The number on your civil id card' | translate }}"></ion-input>
      </ion-list>

      <ion-list>
        <app-date-dropdown [min]="min" [max]="max" presentation="date" formControlName="civil_expiry_date"></app-date-dropdown>
      </ion-list>

      <ion-button (click)="submit()" class="btn-submit" [disabled]="!form.valid || isLoading" type="submit">
        <ion-spinner *ngIf="isLoading"></ion-spinner>
        <span *ngIf="!isLoading">{{ "Submit" | translate }}</span>
      </ion-button>
    </form>
  </div>

  <div class="hidden civil-id-response"></div>

</ion-content>
