<ion-content [attr.dir]="translateService.direction()">

  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Is your mother a Kuwaiti national?" | translate }}</h3>
    <p>{{"This is required for us to know whether we can match you for jobs in the food and beverage sector."|translate}}</p>
    <p>{{"Current Kuwaiti regulations only allow us to issue health cards for Kuwaiti nationals."|translate}}</p>
    <p>{{"This is hopefully temporary as we’re currently working on legalizing health cards for non-Kuwaitis."|translate}}</p>

    <ion-buttons *ngIf="candidate" class="btn-wrapper">

      <ion-button class="btn-yes" 
        [disabled]="isLoading"
        [class.active]="candidate.candidate_mom_kuwaiti == 1" (click)="submit(1)">
        <ion-spinner *ngIf="isLoading == 1"></ion-spinner>
        <span *ngIf="isLoading != 1">{{ "Yes" | translate }}</span>
      </ion-button>

      <ion-button class="btn-no" 
        [disabled]="isLoading"
        [class.active]="candidate.candidate_mom_kuwaiti == 2" (click)="submit(2)">
        <ion-spinner *ngIf="isLoading == 2"></ion-spinner>
        <span *ngIf="isLoading != 2">{{ "No" | translate }}</span>
      </ion-button>
    </ion-buttons>

  </div>
</ion-content>
