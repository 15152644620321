import {Component, OnInit, ViewChild, ElementRef, NgZone} from '@angular/core';
import {
  Platform,
  PopoverController,
  AlertController,
  ActionSheetController,
  ModalController,
  IonButton
} from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
// models
import { Candidate } from 'src/app/models/candidate';
// services
import { AccountService } from 'src/app/providers/logged-in/account.service';
import { TranslateLabelService } from 'src/app/providers/translate-label.service';
import { AwsService } from 'src/app/providers/logged-in/aws.service';
import { SentryErrorhandlerService } from 'src/app/providers/sentry.errorhandler.service';
import { CameraService } from 'src/app/providers/logged-in/camera.service';
// components
import { PhotoActionComponent } from 'src/app/components/photo-action/photo-action';
import { AnalyticsService } from 'src/app/providers/analytics.service';
import { EventService } from 'src/app/providers/event.service';


@Component({
  selector: 'app-civil-id-back',
  templateUrl: './civil-id-back.page.html',
  styleUrls: ['./civil-id-back.page.scss'],
})
export class CivilIdBackPage implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @ViewChild('btnChangePhoto', { static: false }) btnChangePhoto: IonButton;

  public progress;

  public uploadFileSubscription: Subscription;
  
  public uploadingPhoto = false;

  public saving = false;
  public loading = false;

  public form: FormGroup;
  public currentTarget;

  public candidate: Candidate;
  public cloudinaryUrl;
  public interval;

  constructor(
    private _ngzone: NgZone,
    private _fb: FormBuilder,
    private platform: Platform,
    public alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public accountService: AccountService,
    public eventService: EventService,
    public awsService: AwsService,
    public sentryService: SentryErrorhandlerService,
    public translateService: TranslateLabelService,
    private _cameraService: CameraService,
    public analyticsService: AnalyticsService
  ) {
    this.cloudinaryUrl = environment.cloudinaryUrl + 'candidate-photo/';
  }

  ngOnInit() {
    this.analyticsService.page('Civil ID Back page');

    this._initForm();
  }

  ionViewWillLeave() {
    this.analyticsService.track('page_exit', {
      'page': 'Civil ID Back page'
    });
  }

  /**
   * initialize form
   */
  _initForm() {
    this.form = this._fb.group({
      civil_photo_back_path: [this.candidate.candidate_civil_photo_back ? this.awsService.permanentBucketUrl + 'photos/' + this.candidate.candidate_civil_photo_back : '', Validators.required],
      civil_photo_back: [this.candidate.candidate_civil_photo_back, Validators.required]
    });
  }

  /**
   * Display options to update logo
   */
  async updatePhoto(ev) {
    if (this.platform.is('capacitor')) {
      this.mobileUpload();
    } else if (this.form.controls.civil_photo_back.value) {
      const popover = await this.popoverCtrl.create(
        {
          component: PhotoActionComponent,
          componentProps: {
            fileInput: this.fileInput.nativeElement
          },
          event: ev
        }
      );
      popover.present();
      popover.onDidDismiss().then(e => {

        if (!e.data) {
          return null;
        }

        if (e.data && e.data.action === 'remove') {
          return this.removePhoto();
        }
      });
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  /**
   * Remove photo
   */
  removePhoto() {
    this.form.controls.civil_photo_back_path.setValue(null);
    this.form.controls.civil_photo_back.setValue(null);
    this.form.updateValueAndValidity();

    this.candidate.candidate_civil_photo_back = null;

    const removePhotoSubscription = this.accountService.removeCivilPhotoBack().subscribe(() => {
      removePhotoSubscription.unsubscribe();
    });
  }

  /**
   * Upload logo from mobile
   */
  mobileUpload() {

    const SelectSourceLbl = this.translateService.transform('Select image source');
    const LoadLibLbl = this.translateService.transform('Load from Library');
    const UseCamLbl = this.translateService.transform('Use Camera');

    const arrButtons = [
      {
        text: LoadLibLbl,
        handler: () => {
       
          this._cameraService.getImageFromLibrary().then((nativeImageFilePath) => {
            // Upload and process for progress
            this.uploadFileViaNativeFilePath(nativeImageFilePath);
          }, async (err) => {

            const ignoreErrors = [
              'No image picked',
              'User cancelled photos app'
            ];

            if (err && ignoreErrors.indexOf(err.message) > -1) {
                return null;
            }

            const alert = await this.alertCtrl.create({
              header: this.translateService.transform('Error getting picture from Library'),
              message: err.message,
              buttons: [this.translateService.transform('Okay')]
            });

            await alert.present();
            this.progress = null;
          });
        }
      },
      {
        text: UseCamLbl,
        handler: () => {
         
          this._cameraService.getImageFromCamera().then((nativeImageFilePath) => {
            // Upload and process for progress
            this.uploadFileViaNativeFilePath(nativeImageFilePath);
          }, async (err) => {

            const ignoreErrors = [
              'No image picked',
              'User cancelled photos app'
            ];

            if (err && ignoreErrors.indexOf(err.message) > -1) {
                return null;
            }

            const alert = await this.alertCtrl.create({
              header: this.translateService.transform('Error getting picture from Library'),
              message: err.message,
              buttons: [this.translateService.transform('Okay')]
            });

            await alert.present();
            this.progress = null;
          });
        }
      }
    ];

    if (this.form.controls.civil_photo_back.value) {
      arrButtons.push({
        text: this.translateService.transform('Remove Photo'),
        handler: () => {
          this.removePhoto();
        }
      });
    }

    // Display action sheet giving user option of camera vs local filesystem.
    this.actionSheetCtrl.create({
      header: SelectSourceLbl,
      buttons: arrButtons
    }).then(actionSheet => actionSheet.present());
  }

  /**
   * Upload logo by native path
   */
  async uploadFileViaNativeFilePath(uri) {
    this.progress = 1;//show loader

    this.awsService.uploadNativePath(uri).then(o => {
      o.subscribe(event => {
        this._handleFileSuccess(event);
      }, async err => {

        this.progress = null;

        const ignoreErrors = [
          'No image picked',
          'User cancelled photos app',
        ];

        if (
          err && (
            ignoreErrors.indexOf(err.message) > -1 ||
            err.message.includes('aborted')
          ) 
        ) {
          return null;
        }

        // log to slack/sentry to know how many user getting file upload error

        this.sentryService.handleError(err);

        // always show abstract error message

        let message;

        const networkErrors = [
          '504:null',
          'NetworkingError: Network Failure'
        ];

        // networking errors
        if (err && networkErrors.indexOf(err.message) > -1) {
          message = this.translateService.transform('Error uploading file');
        // system errors
        } else if (err.message && err.message.indexOf(':') > -1) {
          message = this.translateService.transform('Error getting file from Library');
        // plugin errors
        } else if (err.message) {
          message = err.message;
        // custom file validation errors
        } else {
          message = err;
        }

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message,
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      });
    });
  }

  /**
   * Upload logo from browser
   * @param event
   */
  async browserUpload(event) {

    const fileList: FileList = event.target.files;

    if (fileList.length == 0) {
      return false;
    }

    const prefix = fileList[0].name.split('.')[0];

    const type = fileList[0].type.split('/')[0];

    if (type != 'image') {
      this.alertCtrl.create({
        message: this.translateService.transform('Invalid File format'),
        buttons: [this.translateService.transform('Okay')]
      }).then(alert => { alert.present(); });
    }
    else
    {
      this.progress = 1;

      this.uploadFileSubscription = this.awsService.uploadFile(fileList[0]).subscribe(event => {
        this._handleFileSuccess(event);
      }, async err => {

        if (!err.message || !err.message.includes('aborted')) {

          const alert = await this.alertCtrl.create({
            header: this.translateService.transform('Error'),
            message: this.translateService.transform('Error while uploading file!'),
            buttons: [this.translateService.transform('Okay')]
          });
  
          await alert.present();
  
          // log to sentry

          this.sentryService.handleError(err);
        }

        if (this.fileInput && this.fileInput.nativeElement) {
          this.fileInput.nativeElement.value = null;
        }

        this.progress = false;
      }, () => {
        clearInterval(this.interval);
        this.progress = 100;
        this.uploadFileSubscription.unsubscribe();
      });
    }
  }

  /**
   * Handle logo upload api response
   * @param event
   */
  _handleFileSuccess(event) {

    // Via this API, you get access to the raw event stream.
    // Look for upload progress events.
    let count = 1;

    if (!this.interval) {
      this.interval = setInterval(() => {
        this._ngzone.run(() => {
          if (count < 100) {
            this.progress = count = count + 1;
          }
        });
      }, 80);
    }

    if (event.type === 'progress') {
      // This is an upload progress event. Compute and show the % done:
      // this.progress = Math.round(100 * event.loaded / event.total);
    } else if (event.Key && event.Key.length > 0) {

      if (this.fileInput && this.fileInput.nativeElement) {
        this.fileInput.nativeElement.value = null;
      }

      const imgLarge = new Image();
      imgLarge.src = event.Location;
      imgLarge.onload = () => {

        this.form.controls.civil_photo_back_path.setValue(event.Location);
        this.form.controls.civil_photo_back.setValue(event.Key);
        this.form.controls.civil_photo_back.markAsDirty();
        this.form.updateValueAndValidity();

        this.accountService.updateCivilPhotoBack(event.Key).subscribe(async response => {

          if (response.operation != 'success') {

            this.form.reset();

            const alert = await this.alertCtrl.create({
              message: this.translateService.errorMessage(response.message),
              buttons: [this.translateService.transform('Okay')],
            });
            alert.present();

            this.progress = null;
            clearInterval(this.interval);
            
          } else  {
            
            this.candidate.candidate_civil_photo_front = response.candidate_civil_photo_front;
            this.candidate.civilExpired = response.civilExpired;

            this.candidate.candidate_civil_photo_back = response.candidate_civil_photo_back;

            //if(response.candidate_civil_expiry_date) {
              this.candidate.candidate_civil_expiry_date = response.candidate_civil_expiry_date;
            //}

            //if(response.candidate_civil_id) {
              this.candidate.candidate_civil_id = response.candidate_civil_id;
            //}

            clearInterval(this.interval);
            this.progress = 100;

            this.eventService.civilUpdated$.next(response);

            this.dismiss({
              refresh: true,
              candidate: this.candidate
            });
          }
        });
      };
    } else if (!this.currentTarget) {
        this.currentTarget = event;
    }
  }

  /**
   * trigger click event on change logo button
   */
  triggerUpdatePhoto($event) {
    $event.stopPropagation();
    document.getElementById('upload-pic').click();
    // this.fileInput.nativeElement.click();
  }

  /**
   * close popup modal
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        this.modalCtrl.dismiss(data);
      }
    });
  }

  /**
   * save photo
   */
  submit() {
    this.saving = true;

    this.accountService.updateCivilPhotoBack(this.form.value.civil_photo_back).subscribe(res => {
      this.saving = false;

      this.eventService.civilUpdated$.next(res);

      if (res.operation == 'success') {
        this.dismiss({
          refresh: true,
          ...res
        });
      }
    }, () => {
      this.saving = false;
    });
  }

  /**
   * cancel file upload
   */
  cancelUpload() {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = null;
    }

    this.progress = null;

    this.loading = false;
    if (this.currentTarget) {
      this.currentTarget.abort();
    }
  }
}
