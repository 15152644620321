<ion-content [attr.dir]="translateService.direction()">
  <ion-list>
    <ion-item routerLink="change-password" (click)="dismiss()">
      <div tabindex="0"></div>
      <ion-icon slot="start" name="key-outline"></ion-icon>
      <ion-label>{{ "Change Password" | translate }}</ion-label>
    </ion-item>
    
    <ion-item tappable *ngIf="!authService.store && authService.candidate_job_search_status" (click)="!updating && updateJobSearchStatus()">
      <div tabindex="0"></div>
      <ion-spinner *ngIf="updating"></ion-spinner>
      <ng-container *ngIf="!updating">
        <ion-icon name="eye-off-outline" slot="start"></ion-icon>
        <ion-label>{{ "I don’t want a job anymore" | translate }}</ion-label>
      </ng-container>
    </ion-item>
    
    <ion-item tappable class="ion-activatable" (click)="translate()">
      <div tabindex="0"></div>
      <ion-icon slot="start" name="globe-outline"></ion-icon>
      <ion-label>
        <span *ngIf="translateService.currentLang != 'en'">English</span>
        <span *ngIf="translateService.currentLang == 'en'">عربى</span>
      </ion-label>
    </ion-item>

    <ion-item tappable class="ion-activatable ion-focusable" (click)="deleteProfile()">
      <div tabindex="0"></div>
      <ion-icon slot="start" name="trash-outline"></ion-icon>
      <ion-label>{{ "Delete Profile" | translate }}</ion-label>
    </ion-item>

    <ion-item lines="none" tappable class="ion-activatable ion-focusable" (click)="logout()">
      <div tabindex="0"></div>
      <ion-icon slot="start" name="log-out-outline"></ion-icon>
      <ion-label>{{ "Logout" | translate }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
