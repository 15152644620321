<ion-content [attr.dir]="translateService.direction()">
  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Enter your login detail to sign in" | translate }}</h3>

    <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" class="classicInput" novalidate>

      <ion-input type="email" inputmode="email" #emailInput enterkeyhint='{{ "Continue" | translate }}'
               formControlName="email" placeholder="{{ 'Your email' | translate }}"></ion-input>

      <div class="password_field">
        <ion-input type="{{type}}" enterkeyhint='{{ "Sign in" | translate }}' #passwordInput formControlName="password" placeholder="{{ 'Your password' | translate }}"></ion-input>
        <ion-button  fill="clear" class="password_change_icon skip-hover" (click)="showPassword()">
          <ion-icon *ngIf="!showPass" name="eye"></ion-icon>
          <ion-icon *ngIf="showPass" name="eye-off"></ion-icon>
        </ion-button>
      </div>

      <ion-button class="btn-signup" [disabled]="!loginForm.valid || isLoading || resettingPassword" type="submit">
        <ion-spinner *ngIf="isLoading"></ion-spinner>
        <span *ngIf="!isLoading">{{ "Sign in" | translate }}</span>
      </ion-button>

      <div class="ion-text-center" *ngIf="!isLoading">
        <a (click)="resetPasswordRequest()" class="btn-password" tappable>
          <ion-spinner *ngIf="resettingPassword"></ion-spinner>
          <span *ngIf="!resettingPassword">{{ "Forgot password?" | translate}}</span>
        </a>
      </div>

      <ion-button (click)="openRegisterPage()" class="btn-register">
        <span>{{ "Don't have an account?" | translate }} </span> <br />
        <b>{{ "Sign up" | translate }}</b>
      </ion-button>
    </form>
  </div>
</ion-content>
