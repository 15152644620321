<ion-card class="card-invitation" [attr.dir]="translateService.direction()" *ngIf="model.request && model.company">
  <ion-card-header>
      <div class="status request-pending" *ngIf="model.request.request_status == 'started' || model.request.request_status == 're_work' || model.request.request_status == 'finished_by_recruitment'">

        <span *ngIf="model.invitation_status == 1 && !this.model.suggestion" class="txt">{{"New Job Invitation!" | translate }}</span>
        <span *ngIf="model.invitation_status == 3 && !this.model.suggestion" class="txt">{{"We're now working on convincing them to hire you. Check back here later to see if you made it." | translate }}</span>
        <span *ngIf="model.invitation_status == 3 && this.model.suggestion && this.model.suggestion.suggestion_status" class="txt">{{"Congratulation, You profile has been shortlisted by Client." | translate }}</span>
        <span *ngIf="model.invitation_status == 2" class="txt">{{ "We’ll keep you updated on any other jobs that pop up." | translate }}</span>
      </div>

      <div class="status request-completed" *ngIf="model.request.request_status == 'delivered'">
        <span *ngIf="model.invitation_status == 1">{{"You didn’t apply for it" | translate}}. </span>
        <span *ngIf="model.invitation_status == 2">{{"You rejected it" | translate}}. </span>
        <span *ngIf="model.invitation_status == 3">{{"You applied " | translate}}. </span>
        <span class="txt">{{ "This job has filled up. If you didn’t make it, we’ll be working on finding you other jobs" | translate }}</span>
      </div>
      <div class="status request-cancelled" *ngIf="model.request.request_status == 'cancelled'">{{"This job was cancelled. They’ll hopefully have another opening soon." | translate }}</div>
  </ion-card-header>

  <ion-card-content>
    <p class="txt-position-title">{{ model.request.request_position_title }}</p>
    <p class="company" *ngIf="translateService.currentLang == 'en'">{{ model.company.company_common_name_en? model.company.company_common_name_en: model.company.company_name }}</p>
    <p class="company" *ngIf="translateService.currentLang != 'en'">{{ model.company.company_common_name_ar? model.company.company_common_name_ar: model.company.company_name }}</p>
    <small class="time-ago">{{ model.invitation_created_at | timeAgo }}</small>

    <div class="badge">
      <ion-badge *ngIf="model.request.request_position_type == 1">{{ "full-time" | translate }}</ion-badge>
      <ion-badge *ngIf="model.request.request_position_type == 2">{{"part-time" | translate }}</ion-badge>
    </div>

    <ng-container *ngIf="model.request.request_status == 'started' || model.request.request_status == 're_work' || model.request.request_status == 'finished_by_recruitment' && model.invitation_status == 1">
      <ion-button (click)="invitationDetail(model)" class="tell-me-more-btn">{{"View details & apply for job" | translate }}</ion-button>
    </ng-container>

  </ion-card-content>
  <ion-card-content class="reply" *ngIf="model.reply">
    <h3>
      {{"You reply:" | translate}}
    </h3>
    <p class="application-msg"> "<span [innerHTML]="model.reply.note_text"></span>"</p>
  </ion-card-content>
</ion-card>
