<ion-content [attr.dir]="translateService.direction()">
  <div class="max-width">
    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Let’s make you an account" | translate }}</h3>

    <form *ngIf="registerForm" [formGroup]="registerForm" (keyup.enter)="onSubmit()" (submit)="onSubmit()">

      <ion-input autocomplte="name" type="text" autocomplete="given-name" #nameInput formControlName="name"
        placeholder="{{ 'Your name' | translate }}"></ion-input>

      <ion-input autocomplte="email" type="email" inputmode="email" autocomplete="email" #nameInput formControlName="email"
        placeholder="{{ 'Your email' | translate }}"></ion-input>

      <ion-input autocomplte="phone" type="telephone" inputmode="tel" autocomplete="tel" #phoneInput
        formControlName="phone" placeholder="{{ 'Your phone number' | translate }}"></ion-input>

      <div class="password_field">
        <ion-input type="{{type}}" autocomplete="off" #passwordInput formControlName="password" placeholder="{{ 'Your password' | translate }}"></ion-input>
        <ion-button  fill="clear" class="password_change_icon skip-hover" (click)="showPassword()">
          <ion-icon *ngIf="!showPass" name="eye"></ion-icon>
          <ion-icon *ngIf="showPass" name="eye-off"></ion-icon>
        </ion-button>
      </div>

      <ion-button class="btn-signup" [disabled]="!registerForm.valid || isLoading" type="submit">
        <ion-spinner *ngIf="isLoading"></ion-spinner>
        <span *ngIf="!isLoading">{{ "Create Account" | translate }}</span>
      </ion-button>
    </form>

    <ion-button (click)="openLoginPage()" class="btn-login">
      {{ "Already a member?" | translate }}&nbsp;<b>{{ "Login" | translate }}</b>
    </ion-button>
      <!--
    <ion-button class="btn-login-bawes" (click)="auth.loginWithRedirect()">
      {{ "Log in with BAWES" | translate }} <ion-icon slot="end" src="assets/images/icon-bawes.svg"></ion-icon>
    </ion-button> -->

  </div>

</ion-content>
