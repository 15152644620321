<ion-content [attr.dir]="translate.direction()">
  <div class="max-width">

    <ion-button fill="clear" (click)="dismiss()" class="btn-close">
      <img src="assets/images/ic_cancel.svg" />
    </ion-button>

    <h3>{{ "Forgot your password?" | translate }}</h3>

    <!--
    <ion-segment [(ngModel)]="segment">
      <ion-segment-button value="email">
        <ion-label>With Email</ion-label>
      </ion-segment-button>
      <ion-segment-button value="sms">
        <ion-label>With SMS</ion-label>
      </ion-segment-button>
    </ion-segment>-->
    
    <ng-container *ngIf="segment == 'email'">
      <p>
        {{ "Enter your email to receive a password reset link. You may need to check your spam folder or unblock contact@studenthub.co" | translate }}
      </p>

      <form *ngIf="resetForm" [formGroup]="resetForm" novalidate (ngSubmit)="sendPassword()">

        <ion-input formControlName="email" autocomplte="email" inputmode="email" enterkeyhint="Submit" placeholder="{{ 'Your Email Address' | translate }}" type="email"></ion-input>

        <ion-button class="btn-signup" [disabled]="!resetForm.valid || isLoading" type="submit">
          <ion-spinner *ngIf="isLoading"></ion-spinner>
          <span *ngIf="!isLoading">{{ "Request Password Reset" | translate }}</span>
        </ion-button>

      </form>
    </ng-container>

    <ng-container *ngIf="segment == 'sms'">
      
      <p>
        {{ "Enter your phone number to receive a password reset link." | translate }}
      </p>

      <form *ngIf="smsForm" [formGroup]="smsForm" novalidate (ngSubmit)="sendSMS()">

        <ion-input formControlName="phone_number" autocomplte="phone" inputmode="tel" enterkeyhint="Submit" placeholder="{{ 'Your Phone Number' | translate }}" type="tel"></ion-input>
        <p>{{ "Phone must be 8 digit number" | translate }}</p>

        <ion-button class="btn-signup" [disabled]="!resetForm.valid || isLoading" type="submit">
          <ion-spinner *ngIf="isLoading"></ion-spinner>
          <span *ngIf="!isLoading">{{ "Request Password Reset" | translate }}</span>
        </ion-button>

      </form>
    </ng-container>

  </div>

</ion-content>
