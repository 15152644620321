<div [attr.dir]="translateService.direction()">


    <!----------------------------- chat-view -------------------------------->

    <div class="chat-view" *ngIf="loading && type == 'chat-view'">
      <ion-skeleton-text animated class="received"></ion-skeleton-text>
      <ion-skeleton-text animated class="sent"></ion-skeleton-text>
      <ion-skeleton-text animated class="received"></ion-skeleton-text>
      <ion-skeleton-text animated class="sent"></ion-skeleton-text>
      <ion-skeleton-text animated class="received"></ion-skeleton-text>
      <ion-skeleton-text animated class="sent"></ion-skeleton-text>
  </div>

  <!----------------------------- chat-list -------------------------------->

  <div class="chat-list" *ngIf="loading && type == 'chat-list'">

      <ion-card>

          <div class="thumbnail-wrapper">
              <ion-thumbnail>
                  <ion-img class="no-logo" src="assets/img/building.svg">
                  </ion-img>
              </ion-thumbnail>
          </div>

          <div class="chat-info-col">
              <span class="company-name">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

              <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
              </p>

              <span class="date-added">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

          </div><!-- END .chat-info-col -->

      </ion-card>

      <ion-card>

          <div class="thumbnail-wrapper">
              <ion-thumbnail>
                  <ion-img class="no-logo" src="assets/img/building.svg">
                  </ion-img>
              </ion-thumbnail>
          </div>

          <div class="chat-info-col">
              <span class="company-name">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

              <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
              </p>

              <span class="date-added">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

          </div><!-- END .chat-info-col -->

      </ion-card>

      <ion-card>

          <div class="thumbnail-wrapper">
              <ion-thumbnail>
                  <ion-img class="no-logo" src="assets/img/building.svg">
                  </ion-img>
              </ion-thumbnail>
          </div>

          <div class="chat-info-col">
              <span class="company-name">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

              <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
              </p>

              <span class="date-added">
                  <ion-skeleton-text animated></ion-skeleton-text>
              </span>

          </div><!-- END .chat-info-col -->

      </ion-card>

  </div>

  <!------------------ text-list -------------------->

  <div class="text-list" *ngIf="loading && type == 'text-list'">
    <ion-list>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
    </ion-list>
  </div>

  <!------------------ personal-info -------------------->

  <div class="personal-info" *ngIf="loading && type == 'personal-info'">

    <ion-list>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <!------------------ national-id -------------------->

  <div class="national-id" *ngIf="loading && type == 'national-id'">

    <ion-list>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <!------------------ first-impression -------------------->

  <div class="first-impression" *ngIf="loading && type == 'first-impression'">

    <ion-list>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <small>
            <ion-skeleton-text animated></ion-skeleton-text>
          </small>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <!------------------ complete-profile -------------------->

  <div class="complete-profile" *ngIf="loading && type == 'complete-profile'">

    <ion-list>

      <ion-item>
        <div tabindex="0"></div>

        <span slot="start" class="step">
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>

        <ion-label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>

        <span slot="start" class="step">
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>

        <ion-label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <span slot="start" class="step">
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>

        <ion-label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-list>

  </div>

  <!------------------ profile -------------------->

  <div class="profile" *ngIf="loading && type == 'profile'">

    <ion-thumbnail>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>

    <h2 class="candidate-name">
      <ion-skeleton-text animated></ion-skeleton-text>
    </h2>

    <h4 class="candidate-name">
      <ion-skeleton-text animated></ion-skeleton-text>
    </h4>

    <div class="birth-date">

      <span class="txt-born-on">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <ion-skeleton-text class="txt-birth-date" animated></ion-skeleton-text>

    </div>

    <div class="objective">
      <ion-skeleton-text animated></ion-skeleton-text>
    </div>

    <ion-item class="video">
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h5>
        <div class="hint">
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
      </ion-label>
    </ion-item>

    <ion-item class="resume">
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h5>
        <div class="hint">
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
      </ion-label>
    </ion-item>

    <ion-item class="skills">
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h5>
        <p>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </p>
      </ion-label>
    </ion-item>

    <ion-item class="experiences">
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h5>
        <p>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
          <span>
            <ion-skeleton-text animated></ion-skeleton-text>
          </span>
        </p>
      </ion-label>
    </ion-item>

    <ion-item class="area">
      <div tabindex="0"></div>
      <ion-label>
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>

    <ion-item class="phone">
      <div tabindex="0"></div>
      <ion-label>
        <b>
          <ion-skeleton-text animated></ion-skeleton-text>
        </b>
      </ion-label>
    </ion-item>

    <ion-item class="email">
      <div tabindex="0"></div>
      <ion-label>
        <b>
          <ion-skeleton-text animated></ion-skeleton-text>
        </b>
      </ion-label>
    </ion-item>

    <ion-item class="nationality">
      <div tabindex="0"></div>
      <ion-label>
        <b>
          <ion-skeleton-text animated></ion-skeleton-text>
        </b>
      </ion-label>
    </ion-item>

    <ion-item class="gender">
      <div tabindex="0"></div>
      <ion-label>
        <b>
          <ion-skeleton-text animated></ion-skeleton-text>
        </b>
      </ion-label>
    </ion-item>

    <ion-item class="university">
      <div tabindex="0"></div>
      <ion-label>
        <b>
          <ion-skeleton-text animated></ion-skeleton-text>
        </b>
      </ion-label>
    </ion-item>

    <ion-item class="driving-license">
      <div tabindex="0"></div>
      <ion-label>
        <span>
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>
      </ion-label>
    </ion-item>

    <ion-item class="mother-nationality">
      <div tabindex="0"></div>
      <ion-label>
        <span>
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>
      </ion-label>
    </ion-item>

    <ion-item tappable class="id-number">
      <div tabindex="0"></div>
      <ion-label>
        <span>
          <ion-skeleton-text animated></ion-skeleton-text>
        </span><br />
        <span class="txt-expiring-on">
          <ion-skeleton-text animated></ion-skeleton-text>
        </span>
      </ion-label>
    </ion-item>

    <ion-item class="civil-front">
      <div tabindex="0"></div>
      <ion-label>
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
        <span>
          <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
        </span>
      </ion-label>
    </ion-item>

    <ion-item class="civil-back">
      <div tabindex="0"></div>
      <ion-label>
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
        <span>
          <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
        </span>
      </ion-label>
    </ion-item>

  </div>

  <!------------------ salary-list -------------------->

  <div class="salary-list" *ngIf="loading && type == 'salary-list'">

    <ion-list>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-skeleton-text></ion-skeleton-text>
      </ion-item>
    </ion-list>
  </div>
</div>